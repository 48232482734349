.navbar {
  position: fixed;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #333;
  color: white;
  width: 100%;
  z-index: 1000;
}

.logo {
  color: #0e4f1f;
  text-decoration: none;
  font-weight: bold;
}

.logo img {
  height: 2.5rem;
  vertical-align: middle;
}

.menu {
  display: flex;
  gap: 20px;
}

.menu a {
  color: white;
  text-decoration: none;
  font-weight: bold;
}

.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.bar {
  width: 30px;
  height: 3px;
  background-color: white;
  margin: 3px 0;
}

@media (max-width: 768px) {
  .menu {
    display: none;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 80px;
    left: 0;
    width: 100%;
    background-color: #333;
    transition: transform 0.3s ease-in-out;
    transform: translateX(-100%);
    z-index: 100;
  }

  .menu.active {
    transform: translateX(0);
  }

  .hamburger {
    display: flex;
    z-index: 101;
  }
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: none;
    z-index: 100;
  }

  .menu.active,
  .hamburger.active,
  .modal-overlay.active {
    display: flex;
    padding: 1rem;
  }
}
