.product-sort {
  display: flex;
  align-items: center;
  background-color: #f9f9f9;
  border-radius: 5px;
  padding: 1rem;
  margin-bottom: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.product-sort label {
  font-size: 1.2rem;
  margin-right: 0.5rem;
}

.product-sort select {
  margin: 0;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}
