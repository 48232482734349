* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  min-height: 100vh;
}

.body {
  display: flex;
  margin-top: 80px;
  gap: 10px;
}

.product-section {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 1rem;
  /* padding: 1rem; */
  overflow: hidden;
}

.product-info {
  display: flex;
  justify-content: space-between;
  /* width: 90%; */
  /* padding: 1rem; */
  border-bottom: 1px solid #000000;
}

@media (max-width: 768px) {
  .body {
    flex-direction: column;
  }
  .product-info {
    flex-direction: column;
    align-items: center;
  }
}
