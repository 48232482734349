.scroll-to-top-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #333;
    color: white;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    display: none;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  
  .scroll-to-top-button.show {
    display: block;
    opacity: 1;
  }
  