.category-desc {
  display: flex;
  align-items: center;
  background-color: #f9f9f9;
  border-radius: 5px;
  padding: 1rem;
  margin-bottom: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.category-desc h3 {
  font-size: 1.2rem;
  margin-bottom: 0;
}

span {
  font-weight: 900;
}
