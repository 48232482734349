.footer {
  background-color: #333;
  padding: 20px;
  text-align: center;
}

.footer-links {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.footer-links a {
    color: white;
    text-decoration: none;
}
