.product-tile {
  display: flex;
  position: relative;
  flex-direction: column;
  border: 1px solid #ccc;
  padding: 5px;
  background-color: #f9f9f9;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.product-tile h3 {
    margin: 1rem;
}

.heart-icon {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(-20%, 20%);
  background-color: #fff;
  color: #e74c3c;
  font-size: 22px;
  padding: 5px;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.price-container {
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
}

.sale-price {
  color: #e74c3c;
  font-weight: bold;
}

.product-tile img {
  max-width: 100%;
  height: auto;
  margin-bottom: 1rem;
}

.original-price {
  color: #888;
  text-decoration: line-through;
  margin-left: 0.5rem;
  font-size: 1.3rem;
}

.normal-price {
  font-weight: bold;
  font-size: 1.3rem;
}

.rating {
  margin-top: 0.5rem;
  color: #f39c12;
  font-weight: bold;
}

.add-to-cart-button {
  margin-top: auto;
  background-color: #333;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  text-transform: uppercase;
}

.add-to-cart-button.added {
  background-color: #27ae60;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
}

.add-to-cart-button:hover {
  transform: scale(1.05);
}

@media (max-width: 768px) {
    .product-tile {
        /* max-width: fit-content; */
    }
}